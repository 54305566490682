
import { defineComponent, onMounted, onUnmounted } from 'vue'
import 'swiper/swiper-bundle.css'

export default defineComponent({
    name: 'mainPage',
    emits: ['backdropClick'],
    props: {
        opened: Boolean,
    },
    setup() {
        let styleTag: HTMLStyleElement | null = null

        function preventBodyScroll() {
            styleTag = document.createElement('style')
            styleTag.appendChild(document.createTextNode('body { overflow: hidden !important }'))
            document.head.appendChild(styleTag)
        }

        onMounted(preventBodyScroll)

        onUnmounted(() => {
            styleTag?.remove()
            styleTag = null
        })
    },
})
