import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainPage from '@/views/mainPage/Index.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'socutno-zobozdravstvo',
        component: MainPage,
        meta: { title: 'Sočutno zobozdravsto' },
    },
    {
        path: '/cenik',
        name: 'cenik',
        component: () => import(/* webpackChunkName: "cenik" */ '@/views/Cenik.vue'),
        meta: { title: 'Sočutno zobozdravsto - Cenik' },
    },
    {
        path: '/pregledi',
        name: 'pregledi',
        component: () => import(/* webpackChunkName: "pregledi" */ '@/views/Pregledi.vue'),
        meta: { title: 'Sočutno zobozdravstvo - Pregledi' },
    },
    {
        path: '/karies',
        name: 'karies',
        component: () => import(/* webpackChunkName: "karies" */ '@/views/Karies.vue'),
        meta: { title: 'Sočutno zobozdravstvo - Karies' },
    },
    {
        path: '/ortopan',
        name: 'ortopan',
        component: () => import(/* webpackChunkName: "ortopan" */ '@/views/Ortopan.vue'),
        meta: { title: 'Sočutno zobozdravstvo - Ortopan' },
    },
    {
        path: '/beljenje',
        name: 'beljenje',
        component: () => import(/* webpackChunkName: "beljenje" */ '@/views/Beljenje.vue'),
        meta: { title: 'Sočutno zobozdravstvo - Beljenje' },
    },
    {
        path: '/parodontologija',
        name: 'parodontologija',
        component: () => import(/* webpackChunkName: "parodontologija" */ '@/views/Parodontologija.vue'),
        meta: { title: 'Sočutno zobozdravstvo - Parodontologija' },
    },
    {
        path: '/otrosko',
        name: 'otrosko',
        component: () => import(/* webpackChunkName: "otrosko" */ '@/views/Otrosko.vue'),
        meta: { title: 'Sočutno zobozdravstvo - Otrosko' },
    },
    {
        path: '/myobrace',
        name: 'myobrace',
        component: () => import(/* webpackChunkName: "myobrace" */ '@/views/Myobrace.vue'),
        meta: { title: 'Sočutno zobozdravstvo - Myobrace' },
    },
    {
        path: '/invisalign',
        name: 'invisalign',
        component: () => import(/* webpackChunkName: "invisalign" */ '@/views/Invisalign.vue'),
        meta: {
            title: 'Invisalign cena - Koliko stane nevidni zobni aparat Invisalign - Sočutno zobozdravstvo',
            description:
                'Cena Invisalign aparata je odvisna od števila opornic in stanja vaših zob. Preverite ceno Invisalign aparata. Smo med vodilnimi ponudniki Invisalign aparatov na Dolenjskem.',
            og_description:
                'Cena Invisalign aparata je odvisna od števila opornic in stanja vaših zob. Preverite ceno Invisalign aparata. Smo med vodilnimi ponudniki Invisalign aparatov na Dolenjskem.',
        },
    },
    {
        path: '/endodontsko',
        name: 'endodontsko',
        component: () => import(/* webpackChunkName: "endodontsko" */ '@/views/Endodontsko.vue'),
        meta: { title: 'Sočutno zobozdravstvo - Endodontsko' },
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('@/views/Admin.vue'),
        meta: { title: 'Sočutno admin' },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
