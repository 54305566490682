export type Review = {
    author_name: string
    author_url: string
    language?: string
    original_language?: string
    profile_photo_url: string
    rating: number
    relative_time_description: string
    text: string
    time: number
    translated: boolean
}

export const cachedReviews: Review[] = [
    {
        author_name: 'Ivana Flek',
        author_url: 'https://www.google.com/maps/contrib/113033048043999576129/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjUP8bDnEfz5E1z7yL00zspFcIAIPY99iCXyCZLDICvY7zfSJo3E=s128-c0x00000000-cc-rp-mo-ba3',
        rating: 5,
        relative_time_description: 'v zadnjem tednu',
        text: 'Zelo prijetna izkusnja prvega obiska nasega malcka,priznam da sem bila v strahu kako se bo obnesal, a bo sploh odprl usta, in po tako prijetnem sprejemu moj Leon sploh ni hotel pejt domov. Zelo strokovno, hkrati pa zelo prijetna in topla obravnava. Vredna vsakega centa.\nPriporočam vsem mamicam katere se ne znajo kam peljat otroka na prvi pregled zobkov.\n\nVse pohvale Urška in Katja! ❤️',
        time: 1718302578,
        translated: false,
    },
    {
        author_name: 'Teja Kozan',
        author_url: 'https://www.google.com/maps/contrib/112726614307754444208/reviews',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a/ACg8ocKJ57n5ExakqyZBK-3P9B4q3Qlxk8Ps_VPOgeu2nrCw-EQ_nA=s128-c0x00000000-cc-rp-mo',
        rating: 5,
        relative_time_description: 'v zadnjem tednu',
        text: '',
        time: 1718085259,
        translated: false,
    },
    {
        author_name: 'Zoran Farkaš',
        author_url: 'https://www.google.com/maps/contrib/107138270982334977828/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjU12541y7pAte0rEz4sc6v5c_6qRBuYdi_RHEou2qqfJERwWDOK=s128-c0x00000000-cc-rp-mo-ba4',
        rating: 5,
        relative_time_description: 'pred 7 meseci',
        text: 'Najboljša , najprijaznejša , sočutna zobozdravnica v Beli krajini.... 🥰\nSpuli zob brez ,da bi sploh kaj čutil 😁',
        time: 1698011906,
        translated: false,
    },
    {
        author_name: 'Katja Bahor',
        author_url: 'https://www.google.com/maps/contrib/110261297256656113381/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjVjGb1HEzf98qoR42yuwNhTNczVQUJ5ZnuHkmC2ICq5vPosgY5a=s128-c0x00000000-cc-rp-mo',
        rating: 5,
        relative_time_description: 'pred 7 meseci',
        text: 'Prijazna, strokovna in komunikativna zobozdravnica, ki resnično dela z občutkom. Ponuja širok nabor zobozdravstvenih storitev, zaradi katerih se ni več potrebno odpeljati v Ljubljano. Prijeten in sproščujoč ambient. Zagotovo se še vrnem 😀.',
        time: 1696593279,
        translated: false,
    },
    {
        author_name: 'Zoran Farkaš',
        author_url: 'https://www.google.com/maps/contrib/107138270982334977828/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjU12541y7pAte0rEz4sc6v5c_6qRBuYdi_RHEou2qqfJERwWDOK=s128-c0x00000000-cc-rp-mo-ba4',
        rating: 5,
        relative_time_description: 'pred 7 meseci',
        text: 'Najboljša , najprijaznejša , sočutna zobozdravnica v Beli krajini.... 🥰\nSpuli zob brez ,da bi sploh kaj čutil 😁',
        time: 1698011906,
        translated: false,
    },
    {
        author_name: 'Boštjan Dolenc',
        author_url: 'https://www.google.com/maps/contrib/108481296949765928669/reviews',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjUlvJ0QMNO_hbzjndzB3n-lxGcmWBOinh93E67jmbA7jYvwUH8=s128-c0x00000000-cc-rp-mo-ba5',
        rating: 5,
        relative_time_description: 'pred 9 meseci',
        language: 'sl',
        original_language: 'sl',
        text: '',
        time: 1691417061,
        translated: false,
    },
    {
        author_name: 'Nina Kapušin',
        author_url: 'https://www.google.com/maps/contrib/112671935274444397803/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjWdHYySFrcSn-gHDsdyNgtf6nYhEUXryAsN6A_eIjsgMeYHrcs=s128-c0x00000000-cc-rp-mo',
        rating: 5,
        relative_time_description: 'pred 11 meseci',
        text: 'Otroku je razložen res vsak postopek in pripomocek, ki se bo približal njegovim zobkom. Otrok je pohvaljen in na koncu nagrajen, ter ponosen, kar se mi zdi zelo pomembno za nadaljne obiske.',
        time: 1686601117,
        translated: false,
    },
    {
        author_name: 'Mira',
        author_url: 'https://www.google.com/maps/contrib/102119478795611219679/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a/ACg8ocJp6prSqWsYXCKsqQCG3hxBLAZjznDinCqj1PiX-XZ3wj7kyg=s128-c0x00000000-cc-rp-mo',
        rating: 5,
        relative_time_description: 'pred enim letom',
        text: 'Sproščujoč ambient, srčna, prijetna in strokovna zobozdravnica. Res si vzame čas in se posveti pacientu.\nNam je izkušnjo obiska zobozdravnika spremenila za 100%.',
        time: 1684509370,
        translated: false,
    },
    {
        author_name: 'Lidija Pezdirc',
        author_url: 'https://www.google.com/maps/contrib/107528924097610364108/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a/ACg8ocJHa4cumm6I7T3ClZ4C4zIsVtICb7-U2e6DUmERfQlJfTDfbw=s128-c0x00000000-cc-rp-mo',
        rating: 5,
        relative_time_description: 'pred enim letom',
        text: 'Zelo prijazna in strokovno podkovana zobozdravnica, ki dela zelo nežno in ve kaj pomeni strah pri zobozdravniku. Zelo lep in sproščujoč ambient. Bili smo zelo zadovoljni.',
        time: 1684990760,
        translated: false,
    },
    {
        author_name: 'Katarina Vraničar',
        author_url: 'https://www.google.com/maps/contrib/104033738189140259737/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjUXR0_fD5lPouqdkz1TLRMGphNIaBkTrPemjoXx_hw0Yhu5p8g=s128-c0x00000000-cc-rp-mo',
        rating: 5,
        relative_time_description: 'pred enim letom',
        text: 'Zares prijazen in profesionalen pristop, zobozdravnica dela nežno, predvsem mi je bilo všeč tudi svetovanje, kako ohraniti oz. izboljšati ustno higieno.',
        time: 1684741352,
        translated: false,
    },
    {
        author_name: 'Nika Banovec',
        author_url: 'https://www.google.com/maps/contrib/104124760239200489103/reviews',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a/ACg8ocLImZvOQhf2ORWaU2stlsQdqjInsJ_9WpRWiK_ArwOVpx0yYg=s128-c0x00000000-cc-rp-mo',
        rating: 5,
        relative_time_description: 'pred 7 meseci',
        text: 'Odlična izkušnja, zelo pohvalno. Zobozdravnica ima res čut za otroka. Vredno vsakega centa 🙂',
        time: 1697480601,
        translated: false,
    },
    {
        author_name: 'Tanja Matkovič',
        author_url:
            'https://www.google.com/maps/contrib/110110164950738007011?hl=en-US&ved=1t:31294&ictx=111',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjUjSWabtOfv1kQ0apT1Au-t31Lo69uraKTWOQ1UTfLaq7BJRu_t=s40-c-rp-mo-br100',
        rating: 5,
        relative_time_description: 'pred 1 letom',
        text: 'Zelo prijazno osebje, sočutno. Prav za take ki se “bojijo” zobozdravnika. Zelo prijeten ambient.',
        time: 1685631345,
        translated: false,
    },
    {
        author_name: 'Karmen Križan',
        author_url:
            'https://www.google.com/maps/contrib/102834410123142713172?hl=en-US&ved=1t:31293&ictx=111',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a/ACg8ocLvXQB8qVhZVSv4wDlP0inQiUt8E-iVUn5nLwVn3Q6zNcQmyQ=s40-c-rp-mo-br100',
        rating: 5,
        relative_time_description: 'pred 1 letom',
        text: 'Sočutno zobozdravstvo je prava izbira za velike in male.Hčerka je z veseljem po prvem obisku rekla, da komaj čaka naslednji obisk ker je zobozdravnica Urška super.Hvala, ker delate s srcem!',
        time: 1694363424,
        translated: false,
    },
    {
        author_name: 'Petra Žlogar Raztresen',
        author_url:
            'https://www.google.com/maps/contrib/103202169970283790039?hl=en-US&ved=1t:31293&ictx=111',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjX02YqcdYydqHVOi_Hc_TE4JfcLbFg0N7cXe1aghaMksz-KxJeH=s40-c-rp-mo-br100',
        rating: 5,
        relative_time_description: 'pred 1 letom',
        text: 'Kot pove že ime; res sočutno zobozdravstvo! Bili smo zelo zadovoljni z obravnavo.',
        time: 1692721824,
        translated: false,
    },
    {
        author_name: 'Nina Koren',
        author_url:
            'https://www.google.com/maps/contrib/116194450023092046432?hl=en-US&ved=1t:31294&ictx=111',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjUAgGVzVoqmzcd8kozSw4PSGODCdFx0bFrRKkdHTEhxlVUE5bwb=s40-c-rp-mo-br100',
        rating: 5,
        relative_time_description: 'pred 1 letom',
        text: 'Zelo prijetna izkusnja prvega obiska nasega malcka, strokovno, hkrati pa zelo prijetna in topla obravnava. Priporocam',
        time: 1691857824,
        translated: false,
    },
    {
        author_name: 'helena lesica',
        author_url:
            'https://www.google.com/maps/contrib/105764737779095953804?hl=en-US&ved=1t:31293&ictx=111',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a-/ALV-UjU1o4_GSpQp3tIoroiuQCPRI6pClClnCRu2VZqz1Xn9P_GojlTp=s40-c-rp-mo-br100',
        rating: 5,
        relative_time_description: 'pred 1 letom',
        text: '',
        time: 1689784224,
        translated: false,
    },
    {
        author_name: 'Dajana Car',
        author_url:
            'https://www.google.com/maps/contrib/102891702876198975200?hl=en-US&ved=1t:31293&ictx=111',
        language: 'sl',
        original_language: 'sl',
        profile_photo_url:
            'https://lh3.googleusercontent.com/a/ACg8ocKkBrivnUhJTjzrJJTB645xBfZSL45dKB_baKLROnlmiPyReQ=s40-c-rp-mo-br100',
        rating: 5,
        relative_time_description: 'pred 1 letom',
        text: '',
        time: 1689438624,
        translated: false,
    },
]
