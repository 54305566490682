<template>
    <div class="working-hours text-sm flex flex-row gap-2 mr-5">
        <ul class="flex flex-col items-end gap-1">
            <li>Pon</li>
            <li>Tor, Sre</li>
            <li>Čet</li>
            <li>Pet</li>
        </ul>
        <ul class="flex flex-col items-start gap-1 text-pale-pink-400 font-bold">
            <li>8:00 - 16:00</li>
            <li>7:00 - 15:00</li>
            <li>13:00 - 20:00</li>
            <li>7:00 - 14:00</li>
        </ul>
    </div>
</template>
