<template>
    <svg
        width="32px"
        height="32px"
        viewBox="0 0 59.541 59.541"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M59.202,5.573c-0.269-0.269-0.651-0.339-0.991-0.238L53.876,1l-0.707-0.707c-0.391-0.391-1.023-0.391-1.414,0
  s-0.391,1.023,0,1.414l0,0l-0.707,0.707c-0.391-0.39-1.023-0.39-1.414,0s-0.391,1.023,0,1.414l-0.707,0.707l0,0
  c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l0,0l-0.707,0.707c-0.391-0.39-1.023-0.391-1.414,0
  C45,7.048,45,7.68,45.391,8.071l-0.707,0.707l0,0c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l0,0L42.562,10.9
  c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l0.707,0.707l4.243,4.243l-4.585,4.585
  c-0.318-0.414-0.76-0.726-1.285-0.847l-4.941-1.141c-1.084-0.249-2.235,0.081-3.022,0.867L14.442,38.55l3.847,9.343L38.51,27.672
  c0.007-0.007,0.017-0.009,0.024-0.016l9.686-9.686l6.364-6.363c0,0,0,0,0.001,0l0-0.001l4.242-4.242c0,0,0.001-0.001,0.001-0.001
  l0.375-0.375C59.593,6.597,59.593,5.964,59.202,5.573z M56.705,6.657l-0.707,0.707l-3.536-3.536l0.707-0.707L56.705,6.657z
  M51.047,5.242l3.536,3.536l-0.707,0.707L50.34,5.95L51.047,5.242z M52.462,10.9l-0.707,0.707l-3.536-3.536l0.707-0.707
  L52.462,10.9z M46.805,9.485l3.536,3.536l-0.707,0.707l-3.536-3.536L46.805,9.485z M43.976,12.314l0.707-0.707l3.536,3.535
  l-0.708,0.708L43.976,12.314z"
        />
        <polygon points="10.274,42.717 14.278,51.903 16.756,49.425 12.909,40.082  " />
        <polygon points="6.164,46.828 10.295,55.886 12.758,53.423 8.754,44.237  " />
        <path
            d="M1.411,51.58c-0.881,0.88-1.366,2.051-1.366,3.297c0,1.246,0.485,2.417,1.366,3.297s2.052,1.366,3.298,1.366
  c1.245,0,2.416-0.485,3.297-1.366l0.779-0.779l-4.132-9.058L1.411,51.58z"
        />
    </svg>
</template>
