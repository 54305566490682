<template>
    <div class="title mb-5">
        <slot />
    </div>
</template>

<style scoped lang="scss">
.title {
    position: relative;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
