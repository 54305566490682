<template>
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 32 32"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="message_x2C__letter_x2C__mail_x2C__emoji_x2C__happy_x2C__e-mail">
            <g id="XMLID_2054_">
                <line
                    id="XMLID_2183_"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    x1="26.5"
                    x2="26.5"
                    y1="15.8"
                    y2="7.5"
                />

                <polyline
                    id="XMLID_2181_"
                    points="    20.5,1.5 5.5,1.5 5.5,15.8   "
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                />

                <polyline
                    id="XMLID_2062_"
                    points="    20.5,1.5 26.479,7.5 20.5,7.5 20.5,4   "
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                />

                <polyline
                    id="XMLID_2061_"
                    points="    21,20.111 29.5,13.5 29.5,30.5 2.5,30.5 2.5,13.5 11.054,20.153   "
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                />

                <polyline
                    id="XMLID_2060_"
                    points="    2.5,29 16,19.5 29.5,29   "
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                />

                <line
                    id="XMLID_2185_"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    x1="26.495"
                    x2="29.5"
                    y1="11.052"
                    y2="13.5"
                />

                <line
                    id="XMLID_2055_"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    x1="2.5"
                    x2="5.5"
                    y1="13.5"
                    y2="11.056"
                />

                <g id="XMLID_2088_">
                    <g id="XMLID_2909_">
                        <path
                            d="      M15,13.521h2c0,0.55-0.45,1-1,1S15,14.07,15,13.521z"
                            id="XMLID_2949_"
                            stroke="#455A64"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                        />

                        <path
                            d="      M20.5,12c0.27,0,0.5,0.23,0.5,0.5S20.77,13,20.5,13S20,12.77,20,12.5S20.23,12,20.5,12z"
                            id="XMLID_2948_"
                            stroke="#455A64"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                        />

                        <path
                            d="      M11.5,12c0.27,0,0.5,0.23,0.5,0.5S11.77,13,11.5,13S11,12.77,11,12.5S11.23,12,11.5,12z"
                            id="XMLID_2910_"
                            stroke="#455A64"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                        />
                    </g>

                    <g id="XMLID_2886_">
                        <path
                            d="      M15,13.521h2c0,0.55-0.45,1-1,1S15,14.07,15,13.521z"
                            id="XMLID_2889_"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                        />

                        <path
                            d="      M20.5,12c0.27,0,0.5,0.23,0.5,0.5S20.77,13,20.5,13S20,12.77,20,12.5S20.23,12,20.5,12z"
                            id="XMLID_2888_"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                        />

                        <path
                            d="      M11.5,12c0.27,0,0.5,0.23,0.5,0.5S11.77,13,11.5,13S11,12.77,11,12.5S11.23,12,11.5,12z"
                            id="XMLID_2887_"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
