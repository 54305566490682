<template>
    <svg
        width="32px"
        height="32px"
        viewBox="0 90 510 300"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <g>
                <path
                    d="M496.714,253.972c-2.303-6.212-4.806-11.841-7.457-16.986c7.269-8.965,11.365-20.225,11.365-32.187v-34.133
          c0-28.232-22.968-51.2-51.2-51.2H62.578c-28.232,0-51.2,22.968-51.2,51.2V204.8c0,11.961,4.096,23.222,11.365,32.187
          c-2.651,5.145-5.154,10.774-7.457,16.986C1.987,289.852,0,331.237,0,352.711c0,21.958,17.864,39.822,39.822,39.822H153.6
          c8.457,0,16.3-2.658,22.756-7.17c6.456,4.512,14.298,7.17,22.756,7.17h113.778c8.457,0,16.3-2.658,22.756-7.17
          c6.456,4.512,14.298,7.17,22.756,7.17h113.778c21.958,0,39.822-17.864,39.822-39.822C512,331.237,510.013,289.852,496.714,253.972
          z M153.6,358.4H39.822c-3.137,0-5.689-2.552-5.689-5.689c0-6.063,0.234-18.84,1.843-34.133H81.7c8.563,7.538,21.456,7.538,30.02,0
          h45.725c1.609,15.293,1.843,28.07,1.843,34.133C159.289,355.848,156.737,358.4,153.6,358.4z M41.71,284.444
          c7.776-32.007,23.459-62.578,55.001-62.578c31.544,0,47.226,30.571,55.001,62.578h-39.992c-8.563-7.539-21.456-7.539-30.02,0
          H41.71z M312.889,358.4H199.111c-3.137,0-5.689-2.552-5.689-5.689c0-6.063,0.234-18.84,1.843-34.133h45.725
          c8.563,7.538,21.456,7.538,30.02,0h45.725c1.609,15.293,1.843,28.07,1.843,34.133C318.578,355.848,316.026,358.4,312.889,358.4z
          M200.998,284.444c7.777-32.007,23.459-62.578,55.001-62.578c31.544,0,47.226,30.571,55.001,62.578H271.01
          c-8.563-7.539-21.456-7.539-30.02,0H200.998z M256,187.733c-25.202,0-59.122,10.853-79.644,61.656
          c-20.522-50.803-54.443-61.656-79.644-61.656c-15.452,0-34.181,4.081-51.091,18.944c-0.068-0.619-0.109-1.245-0.109-1.877v-34.133
          c0-9.411,7.656-17.067,17.067-17.067h386.844c9.411,0,17.067,7.656,17.067,17.067V204.8c0,0.633-0.041,1.258-0.109,1.877
          c-16.91-14.863-35.639-18.944-51.091-18.944c-25.202,0-59.122,10.853-79.644,61.656C315.122,198.587,281.202,187.733,256,187.733z
          M470.289,284.444h-39.991c-8.563-7.539-21.456-7.539-30.02,0h-39.992c7.776-32.007,23.459-62.578,55.001-62.578
          C446.831,221.867,462.513,252.438,470.289,284.444z M472.178,358.4H358.4c-3.137,0-5.689-2.552-5.689-5.689
          c0-6.063,0.234-18.84,1.843-34.133h45.725c8.563,7.538,21.456,7.538,30.02,0h45.725c1.609,15.293,1.843,28.07,1.843,34.133
          C477.867,355.848,475.315,358.4,472.178,358.4z"
                />
            </g>
        </g>
    </svg>
</template>
