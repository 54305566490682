<template>
    <nav v-if="false">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
    </nav>
    <router-view />
</template>

<style lang="scss">
body {
    font-family: theme('fontFamily.text');
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    color: #2c3e50;
}

#app {
    text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    display: flex;
    align-items: center;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
