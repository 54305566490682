import { createApp } from 'vue'
import AButton from '@/components/AButton.vue'
import MainPageTitle from '@/blocks/mainPageTitle.vue'
import Card from '@/blocks/card.vue'
import Delimiter from '@/blocks/delimiter.vue'
import IconBack from '@/icons/IconBack.vue'
import App from '@/App.vue'
import router from '@/router'
import '@/styles/index.css'
import '@/styles/article.scss'
import '@/styles/outlinedForm.scss'
import '@/styles/narocanjeContainer.scss'

function addPreloadLink(href: string) {
    const link = document.createElement('link')
    link.rel = 'preload'
    link.as = 'image'
    link.href = href
    document.head.appendChild(link)
}

addPreloadLink(
    window.innerWidth < 640
        ? require('@/assets/covers/naslovna-mobile.jpg')
        : require('@/assets/covers/naslovna.jpg'),
)

addPreloadLink(require('@/assets/urska.jpg'))

const app = createApp(App)

app.component('AButton', AButton)
app.component('IconBack', IconBack)
app.component('MainPageTitle', MainPageTitle)
app.component('Card', Card)
app.component('Delimiter', Delimiter)

router.beforeEach((to, _, next) => {
    // Set the page title
    if (to.meta.title) {
        document.title = to.meta.title as string
    }

    // Set the meta description
    if (to.meta.description) {
        const metaDescription = document.querySelector('meta[name="description"]')
        if (metaDescription) {
            metaDescription.setAttribute('content', to.meta.description as string)
        } else {
            const meta = document.createElement('meta')
            meta.name = 'description'
            meta.content = to.meta.description as string
            document.head.appendChild(meta)
        }
    }

    if (to.meta.og_description) {
        const ogDescription = document.querySelector('meta[property="og:description"]')
        if (ogDescription) {
            ogDescription.setAttribute('content', to.meta.og_description as string)
        } else {
            const meta = document.createElement('meta')
            meta.setAttribute('property', 'og:description')
            meta.setAttribute('content', to.meta.og_description as string)
            document.head.appendChild(meta)
        }
    }

    next()
})
app.use(router).mount('#app')

window.onload = () => {
    document.body.classList.add('uncover')
}
