<template>
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <g>
                <path
                    d="M459.473,398.099h-53.08c-4.58,0-8.294,3.713-8.294,8.294c0,4.58,3.713,8.294,8.294,8.294h28.889
C389.781,466.16,325.192,495.413,256,495.413c-63.949,0-124.071-24.903-169.291-70.122C41.491,380.071,16.587,319.949,16.587,256
c0-38.695,9.443-77.13,27.307-111.149c2.13-4.055,0.568-9.069-3.487-11.199c-4.055-2.131-9.07-0.57-11.199,3.487
C10.101,173.527,0,214.629,0,256c0,68.38,26.628,132.668,74.981,181.019S187.62,512,256,512
c75.775,0,146.375-32.824,195.179-90.365v37.838c0,4.58,3.713,8.294,8.294,8.294c4.58,0,8.294-3.713,8.294-8.294v-53.08
C467.767,401.813,464.053,398.099,459.473,398.099z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M437.019,74.981C388.668,26.628,324.38,0,256,0c-40.549,0-79.333,9.206-115.277,27.364
c-30.38,15.347-57.772,36.991-79.902,63.007V52.527c0-4.58-3.713-8.294-8.294-8.294c-4.58,0-8.294,3.713-8.294,8.294v53.08
c0,4.58,3.713,8.294,8.294,8.294h53.08c4.58,0,8.294-3.713,8.294-8.294c0-4.58-3.713-8.294-8.294-8.294H76.771
c20.1-22.646,44.508-41.543,71.431-55.144C181.805,25.194,218.074,16.587,256,16.587c63.949,0,124.071,24.903,169.291,70.122
c45.219,45.22,70.122,105.341,70.122,169.291c0,38.695-9.443,77.13-27.307,111.149c-2.13,4.055-0.568,9.069,3.487,11.199
c1.231,0.646,2.549,0.952,3.848,0.952c2.984,0,5.866-1.615,7.349-4.44C501.899,338.473,512,297.371,512,256
C512,187.62,485.372,123.332,437.019,74.981z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M379.853,141.546H132.147c-19.207,0-34.834,15.626-34.834,34.834v168.086c0,19.207,15.627,34.834,34.834,34.834h247.706
c19.207,0,34.834-15.627,34.834-34.834V176.38C414.687,157.173,399.06,141.546,379.853,141.546z M132.147,158.134h247.706
c1.699,0,3.336,0.251,4.897,0.687l-110.182,103.7c-10.413,9.799-26.725,9.8-37.137,0l-110.181-103.7
C128.812,158.385,130.449,158.134,132.147,158.134z M113.901,344.466V176.38c0-2.208,0.414-4.316,1.136-6.276L211,260.424
l-95.964,90.319C114.314,348.783,113.901,346.675,113.901,344.466z M379.853,362.713H132.147v0c-1.699,0-3.336-0.251-4.897-0.687
l95.851-90.214l2.961,2.788c8.393,7.9,19.165,11.849,29.937,11.849s21.544-3.95,29.937-11.849l2.961-2.788l95.851,90.214
C383.188,362.462,381.551,362.713,379.853,362.713z M398.099,344.466c0,2.208-0.414,4.316-1.136,6.276L301,260.422l95.964-90.318
c0.722,1.961,1.136,4.068,1.136,6.276V344.466z"
                />
            </g>
        </g>
    </svg>
</template>
