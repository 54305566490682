
import { defineComponent, computed } from 'vue'

export default defineComponent({
    setup() {
        return {
            year: computed(() => new Date().getFullYear()),
        }
    },
})
