const monthNames = [
    'januarja',
    'februarja',
    'marca',
    'aprila',
    'maja',
    'junija',
    'julija',
    'avgusta',
    'septembra',
    'oktobra',
    'novembra',
    'decembra',
]

const dayNames = ['nedelje', 'ponedeljka', 'torka', 'srede', 'četrtka', 'petka', 'sobote']

export function isValidEmail(email: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
}

export async function preloadImages(imageUrls: string[]): Promise<void[]> {
    const promises: Promise<void>[] = []

    imageUrls.forEach(url => {
        const image = new Image()
        image.src = url
        promises.push(
            new Promise(resolve => {
                image.onload = () => resolve()
            }),
        )
    })

    return Promise.all(promises)
}

export function isDevMode() {
    return process.env.NODE_ENV === 'development'
}

export function daysBetween(date1: Date, date2: Date): number {
    const diffInMs = date2.getTime() - date1.getTime()
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
    return diffInDays
}

export function formatDate(date: Date): string {
    return date.toLocaleDateString('sl-SI').replaceAll(' ', '')
}

export function formatDays(days: number): string {
    return days === 1 ? '1 dan' : `${days} dni`
}

export function strToDate(dateStr: string | undefined): Date {
    if (!dateStr) return new Date()
    const [day, month, year] = dateStr.split('.').map(Number)
    return new Date(year, month - 1, day)
}

export function dayAndMonth(date: Date): string {
    return `${date.getDate()}. ${date.getMonth() + 1}.`
}

export function slovenianDayMonth(date: Date): string {
    return `${date.getDate()}. ${monthNames[date.getMonth()]}.`
}

export function slovenianDayOfWeek(date: Date): string {
    return dayNames[date.getDay()]
}
