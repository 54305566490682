<template>
    <svg
        width="32px"
        height="32px"
        viewBox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m495,396.1c-8-8-20.9-8-28.9,0l-20.6,20.6-25.5-25.5 68.3-68.3c8-8 8-20.9 0-28.9-8-8-20.9-8-28.9,0l-16.4,16.4-168.9-169.1c-36.6-36.6-96.1-36.6-132.7,0-31.4,31.4-35.8,79.6-13.3,115.8l52.5-52.5c8-8 20.9-8 28.9,0 8,8 8,20.9 0,28.9l-54.3,54.3 34.9,34.9 54.3-54.3c8-8 20.9-8 28.9,0 8,8 8,20.9 0,28.9l-54.3,54.3 34.9,34.9 54.3-54.3c8-8 20.9-8 28.9,0 8,8 8,20.9 0,28.9l-54.3,54.3 27.8,27.8-16.4,16.4c-8,8-8,20.9 0,28.9 4,4 16.4,11.2 28.9,0l68.3-68.3 25.5,25.5-20.6,20.6c-8,8-8,20.9 0,28.9 4,4 15.8,11.1 28.9,0l70-70c7.8-8.2 7.8-21.1-0.2-29.1z"
        />
        <path
            d="M45.9,17C37.9,9,25,9,17,17c-8,8-8,20.9,0,28.9L99.2,128c4-5.4,8.4-10.7,13.3-15.6c4.9-4.9,10.2-9.2,15.6-13.2L45.9,17z"
        />
    </svg>
</template>
