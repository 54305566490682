import { renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "backdrop sm:py-rem6",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('backdropClick')))
    }, [
      _createElementVNode("div", {
        class: "modal-window sm:rounded-lg",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('backdropClick')), ["escape"]))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 32)
    ])
  ]))
}