<template>
    <svg
        width="32px"
        height="32px"
        viewBox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                class="st0"
                d="M313.22,216.555c-9.492-15.477-22.898-25.673-37.156-31.241c-14.338-5.558-29.239-7.055-43.646-7.055
    c-2.348,0-4.706,0.069-6.995,0.139c-9.333,0.287-18.321,1.069-26.812,1.863c-10.473,0.921-20.184,1.842-27.951,1.842
    c-7.768,0-17.468-0.921-27.952-1.842c-10.562-1.011-22.035-2.002-33.806-2.002c-9.621,0-19.39,0.634-29.16,2.774
    c-14.556,3.072-29.17,9.632-40.941,21.759C26.96,214.91,18.479,231.883,14.06,254.216c-2.209,10.9-3.14,21.957-3.14,33.014
    c0,34.154,8.986,68.03,19.608,98.766c10.631,30.656,23.106,58.4,30.458,79.008c5.202,14.694,12.336,26.099,21.036,34.164
    C90.72,507.214,101.273,512,112.112,512c9.909,0,19.54-4.132,26.525-11.127c7.065-6.916,11.77-16.329,13.912-27.169
    c1.932-9.768,2.774-20.034,3.636-30.091c0.643-7.481,1.208-14.832,2.07-21.392c1.208-9.908,3.418-18.112,5.776-21.966
    c1.139-2.002,2.071-2.844,2.844-3.349c0.792-0.436,1.506-0.862,3.784-0.862c2.002,0,2.854,0.357,3.567,0.714
    c0.574,0.287,1.14,0.792,2.001,1.863c1.428,1.704,3.212,5.41,4.489,10.7c2.002,7.769,3.131,18.529,4.072,29.864
    c0.922,11.414,1.774,23.324,3.984,34.52c2.14,10.84,6.846,20.253,13.911,27.248C209.668,507.868,219.3,512,229.208,512
    c10.84,0,21.462-4.786,30.091-12.832c8.709-8.065,15.834-19.47,21.036-34.164c7.352-20.609,19.826-48.352,30.458-79.008
    c10.622-30.736,19.609-64.612,19.609-98.766v-0.356c0-10.919-0.931-21.897-3.141-32.658
    C324.268,239.294,319.562,226.82,313.22,216.555z M300.022,313.042c-2.923,21.253-9.056,42.932-16.19,63.611
    c-10.126,29.24-22.314,56.408-30.31,78.8c-4.062,11.335-9.125,18.687-13.614,22.819c-4.568,4.132-8.204,5.133-10.701,5.202
    c-2.358-0.069-4.28-0.772-6.56-2.923c-2.288-2.209-4.637-6.202-5.915-12.405c-1.506-7.501-2.358-16.903-3.14-26.881
    c-0.644-7.431-1.289-15.121-2.21-22.75c-1.566-11.414-3.636-22.472-9.344-32.519c-2.923-4.994-6.916-9.849-12.406-13.267
    c-5.499-3.497-12.196-5.281-18.974-5.202c-5.984,0-11.978,1.347-17.122,4.131c-4.41,2.428-8.125,5.777-10.978,9.414
    c-4.984,6.49-7.63,13.693-9.552,21.114c-2.854,11.117-3.923,23.096-4.846,34.65c-1,11.552-1.853,22.68-3.566,31.31
    c-1.288,6.202-3.636,10.196-5.925,12.405c-2.348,2.151-4.202,2.854-6.56,2.923c-2.487-0.069-6.133-1.069-10.691-5.202
    c-4.499-4.132-9.562-11.484-13.624-22.819c-7.986-22.392-20.183-49.56-30.31-78.8c-10.126-29.239-18.112-60.411-18.043-89.422
    c0-9.422,0.784-18.617,2.566-27.465c2.428-12.188,6.133-21.323,10.345-28.239c6.41-10.265,13.98-15.833,23.393-19.677
    c9.404-3.706,20.818-5.064,33.154-5.064c10.057,0,20.689,0.862,31.162,1.853c10.483,0.922,20.758,2.002,30.596,2.002
    c9.838,0,20.114-1.08,30.596-2.002c3.22-0.306,6.411-0.514,9.632-0.772l3.735,8.739l-14.485,9.62L171.83,245.12l53.484,17.836
    l-17.824,53.484l49.918-21.392l10.691,57.042l12.911-20.034l19.182-29.883l0.952,0.316
    C300.845,305.996,300.518,309.505,300.022,313.042z"
            />
            <polygon
                class="st0"
                points="293.483,182.104 373.908,87.816 335.088,79.494 365.585,0 274.073,107.237 321.216,110.002"
            />
            <polygon
                class="st0"
                points="501.081,119.325 368.458,167.162 408.042,192.924 348.246,241.83 464.807,199.74 435.202,173.286"
            />
        </g>
    </svg>
</template>
